import React from 'react';
import { Field } from 'formik';
import { Box, Flex } from 'glints-aries';
import trim from 'lodash/trim';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import isEmail from 'validator/lib/isEmail';

import { ProfilePictureUploader } from 'src/modules/Profile/Components/ProfilePictureUploader';

import * as S from './EditExpertProfileInfoForm.sc';
import { editExpertProfileInfoForm } from './errorMessages';
import { FieldsRequiredInfoBox } from './FieldsRequiredInfoBox';
import { TextInput } from './Inputs/TextInput';
import { required, validatePhoneNumber } from './utils/validators';

interface Props {
  email?: string;
  hideProfilePicUploader: boolean;
}

export const validateSecondaryEmail =
  (
    emailFormatErrorMessage: string,
    sameEmailErrorMessage: string,
    primaryEmail: string
  ) =>
  (value: string) => {
    if (!value) return undefined;
    const trimmedValue = trim(value);
    if (!isEmail(trimmedValue)) {
      return emailFormatErrorMessage;
    }

    if (trimmedValue === primaryEmail) {
      return sameEmailErrorMessage;
    }

    return undefined;
  };
export const EditExpertProfileInfoForm = (props: Props) => {
  const intl = useIntl();

  return (
    <S.Container>
      <FieldsRequiredInfoBox />
      <If condition={!props.hideProfilePicUploader}>
        <Flex mt={16} alignItems="center" justifyContent="center">
          <S.ProfilePicture>
            <ProfilePictureUploader />
          </S.ProfilePicture>
          <FormattedMessage
            id="edit-expert-info-modal_photo_requirements"
            defaultMessage="Photo Requirements:{br}
          - Self-portrait, showing your face clearly{br}
          - The resolution is min 1280 px{br}
          - Colored picture{br}"
            values={{ br: <br /> }}
            tagName={S.ProfilePicAdditionalInfoText}
          />
        </Flex>
      </If>

      <S.TwoColumn mt={24}>
        <Field
          component={TextInput}
          name="firstName"
          label={intl.formatMessage(
            defineMessage({
              defaultMessage: 'First name',
              id: 'edit-expert-info-modal_first_name',
            })
          )}
          validate={required(
            intl.formatMessage(editExpertProfileInfoForm.firstName)
          )}
        />
        <Field
          component={TextInput}
          name="lastName"
          label={intl.formatMessage(
            defineMessage({
              defaultMessage: 'Last name',
              id: 'edit-expert-info-modal_last_name',
            })
          )}
        />
      </S.TwoColumn>

      <Box mt={16}>
        <Field
          component={TextInput}
          name="phone"
          label={intl.formatMessage(
            defineMessage({
              defaultMessage: 'Phone number',
              id: 'edit-expert-info-modal_phone',
            })
          )}
          validate={(value: string) =>
            required(intl.formatMessage(editExpertProfileInfoForm.blankPhone))(
              value
            ) ||
            validatePhoneNumber(
              intl.formatMessage(editExpertProfileInfoForm.invalidPhone)
            )(value)
          }
        />
      </Box>
      <FormattedMessage
        id="edit-expert-info-modal_example_phone"
        defaultMessage="Example: +6281912345678"
        tagName={S.PlaceHolderText}
      />
      <FormattedMessage
        id="edit-expert-info-modal_email"
        defaultMessage="Email"
        tagName={S.PlaceHolderText}
      />
      {props.email && (
        <S.Text mt={8} bold={true} data-testid="edit-expert-form-email">
          {props.email}
        </S.Text>
      )}
      <Box mt={16}>
        <Field
          component={TextInput}
          name="secondaryEmail"
          label={intl.formatMessage(
            defineMessage({
              defaultMessage: 'Secondary Email (Optional)',
              id: 'edit-expert-info-modal_secondary_email',
            })
          )}
          validate={validateSecondaryEmail(
            intl.formatMessage(editExpertProfileInfoForm.invalidEmail),
            intl.formatMessage(
              editExpertProfileInfoForm.secondaryEmailSameAsPrimaryEmail
            ),
            props.email
          )}
        />
      </Box>
    </S.Container>
  );
};
