import { ScreenSize, SecondaryColor, Typography } from 'glints-aries';
import styled from 'styled-components';

const { Title: AriesTitle, Paragraph } = Typography;

export const ProfileInfoBackgroundContainer = styled.div<{ mt?: number }>`
  background-color: #fbfbfb;
  align-items: center;
  padding: 32px 0;
  text-align: left;
`;

export const ProfileInfoDesktopVersion = styled.div`
  display: none;
  @media (min-width: ${ScreenSize.desktopS}px) {
    display: flex;
    align-items: center;
    gap: 45px;
  }
`;

export const ProfileInfoUserProfile = styled.div`
  width: 140px;
  height: 140px;
  position: relative;
`;

export const ProfileInfoUserDetails = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const UserNameWithEditButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
`;

export const ProfileInfoUserName = styled(AriesTitle).attrs({ tag: 'h3' })`
  font-size: 22px;
  font-weight: bold;
`;

export const ProfileInfoEditPersonalInfo = styled(Paragraph).attrs({
  variant: 'caption',
  uppercase: true,
})`
  color: ${SecondaryColor.actionblue};
  font-weight: bold;
  margin-left: 10px;
`;

export const BlockTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #777777;
`;

export const ProfileInfoText = styled(Paragraph).attrs({
  variant: 'regular',
})`
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ProfileInfoDetailGrid = styled.div`
  display: grid;
  column-gap: 72px;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  justify-content: start;
`;

export const ProfileInfoDetailGridCell = styled.div`
  overflow: hidden;
  max-width: 100%;
`;

export const MobileTabletViewDataContainer = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;

export const MobileTabletViewLabelValueContainer = styled.div``;
export const ProfileInfoTabletMobileVersion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${ScreenSize.desktopS}px) {
    display: none;
  }
  ${MobileTabletViewLabelValueContainer} {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  ${MobileTabletViewDataContainer} {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: center;
  }
  ${BlockTitle} {
    font-size: 14px;
  }
`;
