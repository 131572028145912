import React, { useState } from 'react';
import { Divider, EditIcon, Flex, SecondaryColor } from 'glints-aries';
import { FormattedMessage } from 'react-intl';

import { joinAttributes } from 'src/common/filters';
import UnstyledButton from 'src/components/Button/UnstyledButton';
import GlintsContainer from 'src/components/GlintsContainer';
import { ProfilePictureUploader } from 'src/modules/Profile/Components/ProfilePictureUploader';
import { MeValue } from 'src/reducers/user/me';

import { Spacing } from '../../styles.sc';
import { ApplicationDataGridEmptyColumn } from '../ApplicationDataGrid/ApplicationDataGrid';
import { EditExpertProfileInfoModal } from '../Modals/EditExpertProfileInfoModal/EditExpertProfileInfoModal';
import { EditProfileInfoFormValues } from '../Modals/EditExpertProfileInfoModal/types';
import * as S from './ProfileInformation.sc';
import { ProfileInfoBackgroundContainer } from './ProfileInformation.sc';
import { ProfileInformationData } from './types';

interface Props {
  user: MeValue;
  secondaryEmail?: string;
  updateProfileMutation(
    values: EditProfileInfoFormValues
  ): Promise<ProfileInformationData>;
}

export const ProfileInformation = ({
  user,
  secondaryEmail: _email,
  updateProfileMutation,
}: Props) => {
  const { firstName, lastName, email: primaryEmail, phone } = user;
  const fullName = joinAttributes([firstName, lastName], ' ', 'User');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [secondaryEmail, setSecondaryEmail] = useState(_email);

  const handleSubmit = (secondaryEmail: string) => {
    setSecondaryEmail(secondaryEmail);
  };

  return (
    <ProfileInfoBackgroundContainer>
      <GlintsContainer>
        <EditExpertProfileInfoModal
          isModalOpen={isModalOpen}
          updateProfileMutation={updateProfileMutation}
          user={user}
          onClose={() => setIsModalOpen(false)}
          onSuccess={handleSubmit}
          secondaryEmail={secondaryEmail}
        />
        <S.ProfileInfoDesktopVersion>
          <S.ProfileInfoUserProfile>
            <ProfilePictureUploader />
          </S.ProfileInfoUserProfile>
          <S.ProfileInfoUserDetails>
            <S.UserNameWithEditButtonContainer>
              <S.ProfileInfoUserName>{fullName}</S.ProfileInfoUserName>
              <UnstyledButton onClick={() => setIsModalOpen(true)}>
                <Flex justifyContent="center" alignItems="center">
                  <EditIcon
                    color={SecondaryColor.actionblue}
                    height={15}
                    width={15}
                  />
                  <FormattedMessage
                    id="expert-application-personal-info_edit_info"
                    defaultMessage="EDIT PROFILE"
                    tagName={S.ProfileInfoEditPersonalInfo}
                  />
                </Flex>
              </UnstyledButton>
            </S.UserNameWithEditButtonContainer>
            <S.ProfileInfoDetailGrid>
              <S.ProfileInfoDetailGridCell>
                <S.BlockTitle>
                  <FormattedMessage
                    id="expert-application-personal-info_phone"
                    defaultMessage="PHONE NUMBER"
                  />
                </S.BlockTitle>
                <Spacing value={8} />
                {phone ? (
                  <S.ProfileInfoText>{phone}</S.ProfileInfoText>
                ) : (
                  <ApplicationDataGridEmptyColumn />
                )}
              </S.ProfileInfoDetailGridCell>
              <S.ProfileInfoDetailGridCell>
                <S.BlockTitle>
                  <FormattedMessage
                    id="expert-application-personal-info_email"
                    defaultMessage="EMAIL"
                  />
                </S.BlockTitle>
                <Spacing value={8} />
                <S.ProfileInfoText>{primaryEmail}</S.ProfileInfoText>
              </S.ProfileInfoDetailGridCell>
              <S.ProfileInfoDetailGridCell>
                <S.BlockTitle>
                  <FormattedMessage
                    id="expert-application-personal-info_secondary_email"
                    defaultMessage="SECONDARY EMAIL"
                  />
                </S.BlockTitle>
                <Spacing value={8} />
                {secondaryEmail ? (
                  <S.ProfileInfoText>{secondaryEmail}</S.ProfileInfoText>
                ) : (
                  <ApplicationDataGridEmptyColumn testId="profile-info-secondary-email" />
                )}
              </S.ProfileInfoDetailGridCell>
            </S.ProfileInfoDetailGrid>
          </S.ProfileInfoUserDetails>
        </S.ProfileInfoDesktopVersion>
        <S.ProfileInfoTabletMobileVersion>
          <S.ProfileInfoUserProfile>
            <ProfilePictureUploader />
          </S.ProfileInfoUserProfile>
          <Spacing value={32} />
          <S.ProfileInfoUserName>{fullName}</S.ProfileInfoUserName>
          <Spacing value={32} />
          <S.MobileTabletViewDataContainer>
            <S.MobileTabletViewLabelValueContainer>
              <S.BlockTitle>
                <FormattedMessage
                  id="expert-application-personal-info_phone"
                  defaultMessage="PHONE NUMBER"
                />
              </S.BlockTitle>
              {phone ? (
                <S.ProfileInfoText>{phone}</S.ProfileInfoText>
              ) : (
                <ApplicationDataGridEmptyColumn />
              )}
            </S.MobileTabletViewLabelValueContainer>
            <S.MobileTabletViewLabelValueContainer>
              <S.BlockTitle>
                <FormattedMessage
                  id="expert-application-personal-info_email"
                  defaultMessage="EMAIL"
                />
              </S.BlockTitle>
              <S.ProfileInfoText>{primaryEmail}</S.ProfileInfoText>
            </S.MobileTabletViewLabelValueContainer>
            <S.MobileTabletViewLabelValueContainer>
              <S.BlockTitle>
                <FormattedMessage
                  id="expert-application-personal-info_secondary_email"
                  defaultMessage="SECONDARY EMAIL"
                />
              </S.BlockTitle>
              {secondaryEmail ? (
                <S.ProfileInfoText>{secondaryEmail}</S.ProfileInfoText>
              ) : (
                <ApplicationDataGridEmptyColumn testId="profile-info-secondary-email-mobile-view" />
              )}
            </S.MobileTabletViewLabelValueContainer>
          </S.MobileTabletViewDataContainer>
          <Spacing value={32} />
          <Divider theme="grey" />
          <Spacing value={26} />
          <UnstyledButton onClick={() => setIsModalOpen(true)}>
            <Flex justifyContent="center" alignItems="center">
              <EditIcon
                color={SecondaryColor.actionblue}
                height={15}
                width={15}
              />
              <FormattedMessage
                id="expert-application-personal-info_edit_info"
                defaultMessage="EDIT PROFILE"
                tagName={S.ProfileInfoEditPersonalInfo}
              />
            </Flex>
          </UnstyledButton>
        </S.ProfileInfoTabletMobileVersion>
      </GlintsContainer>
    </ProfileInfoBackgroundContainer>
  );
};
