import React, { useCallback } from 'react';
import { FieldProps } from 'formik';
import { PrimaryColor } from 'glints-aries';
import styled from 'styled-components';

import TextField from 'src/components/LegacyTextField/TextField';

const ErrorMessage = styled.p`
  color: ${PrimaryColor.glintsred};
  display: block;
`;

/**
 * @deprecated Because this is formik specific input
 * and we have started migrating to react-hook-form
 */
export const TextInput: React.FC<
  React.PropsWithChildren<
    FieldProps & {
      label: React.ReactNode;
    }
  >
> = ({ field, form: { touched, errors }, label }) => {
  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    // prevent form submission when enter key is pressed
    if (e.keyCode === 13) e.preventDefault();
  }, []);
  return (
    <div>
      <TextField
        {...field}
        type="text"
        label={label}
        small={true}
        onKeyDown={onKeyDown}
      />
      {touched[field.name] && errors[field.name] && (
        <ErrorMessage>
          <>{errors[field.name]}</>
        </ErrorMessage>
      )}
    </div>
  );
};
