import React, { ChangeEvent, useState } from 'react';
import { Loading, ProfilePicture } from 'glints-aries';
import { useDispatch, useSelector } from 'react-redux';

import { showCloseableNotification } from 'src/actions/notifications';
import { uploadImageToS3WithAuth } from 'src/actions/uploadImageToS3';
import { persistMe } from 'src/actions/user/me';
import { ProfilePicture as GlintsProfilePicture } from 'src/components/GlintsPicture';
import {
  ProfileFields,
  trackEditProfileField,
} from 'src/modules/Profile/GTMActions';
import { getUser } from 'src/selectors/user';

import * as S from './ProfilePictureUploader.sc';

export const ProfilePictureUploader: React.FC<
  React.PropsWithChildren<S.ContainerProps>
> = ({ hideBackground }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const me = useSelector(getUser);
  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setIsLoading(true);
    dispatch(
      uploadImageToS3WithAuth(
        files[0],
        'profile-picture',
        ({ filename }) => {
          dispatch(
            persistMe(true, {
              profilePic: filename,
            })
          );

          dispatch(trackEditProfileField(ProfileFields.PHOTO));
          setIsLoading(false);
        },
        (_, message) => {
          dispatch(
            showCloseableNotification({
              type: 'danger',
              message,
              autoCloseInterval: 5000,
            })
          );
        }
      )
    );
  };
  return (
    <S.Container hideBackground={hideBackground}>
      {isLoading && <Loading />}
      {!isLoading && (
        <ProfilePicture editable={true}>
          <input
            type="file"
            onChange={onFileChange}
            accept="image/png, image/jpeg"
          />
          <GlintsProfilePicture
            profilePic={me.profilePic}
            userID={me.id}
            sizes="140px"
            breakpoints={140}
            alt="Profile Picture"
          />
        </ProfilePicture>
      )}
    </S.Container>
  );
};
