import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateMeValue } from 'src/actions/user/me';
import { AlertBox } from 'src/components/AlertBox';
import { MeValue } from 'src/reducers/user/me';

import { EditExpertProfileInfoForm } from '../../Forms/EditExpertProfileInfoForm';
import { ProfileInformationData } from '../../ProfileInformation/types';
import { UpsertDataModal } from '../UpsertDataModal/UpsertDataModal';
import { EditProfileInfoFormValues } from './types';

interface Props {
  isModalOpen: boolean;
  user: MeValue;
  onClose?(): void;
  secondaryEmail?: string;
  onSuccess?(secondaryEmail: string): void;
  updateProfileMutation(
    values: EditProfileInfoFormValues
  ): Promise<ProfileInformationData>;
  hideProfilePicUploader?: boolean;
}

export const EditExpertProfileInfoModal = ({
  isModalOpen,
  onClose,
  user,
  secondaryEmail,
  onSuccess,
  updateProfileMutation: mutation,
  hideProfilePicUploader = false,
}: Props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<Error>(null);
  const me = user;
  const initialValues: EditProfileInfoFormValues = {
    firstName: me.firstName || '',
    lastName: me.lastName || '',
    phone: me.phone || '',
    secondaryEmail: secondaryEmail || '',
  };

  const onSubmit = async (values: EditProfileInfoFormValues) => {
    if (error) {
      setError(null);
    }

    const data = await mutation(values);
    const updatedUser: MeValue = {
      ...user,
      ...data.user,
    };
    dispatch(updateMeValue(updatedUser));
    onSuccess?.(data.secondaryEmail);
  };

  return (
    <>
      <UpsertDataModal<EditProfileInfoFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        onClose={onClose}
        isOpen={isModalOpen}
        onSubmitError={(error) => setError(error)}
        titleMessage={{
          id: 'edit-expert-info-modal_title',
          defaultMessage: 'Personal Information',
        }}
        hideFooterCancelCta={true}
        testIds={{
          submitCta: 'edit-expert-profile-info-modal-submit-cta',
        }}
      >
        <EditExpertProfileInfoForm
          email={me.email}
          hideProfilePicUploader={hideProfilePicUploader}
        />
        {error && (
          <AlertBox
            message={'Oops something went wrong. Please try again.'}
            variant={'error'}
          />
        )}
      </UpsertDataModal>
    </>
  );
};
