import { Greyscale, Typography } from 'glints-aries';
import styled from 'styled-components';

const { Paragraph } = Typography;

export const Container = styled.div`
  text-align: left;
`;

export const ProfilePicture = styled.div`
  width: 140px;
  height: 140px;
`;

export const TwoColumn = styled.div<{ mt?: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: ${(props) => props.mt || 0}px;
`;

export const Text = styled(Paragraph).attrs({ variant: 'caption' })<{
  color?: string;
  mt?: number;
}>`
  color: ${(props) => props.color || Greyscale.black};
  margin-top: ${(props) => props.mt || 0}px;
`;

export const ProfilePicAdditionalInfoText = styled(Text).attrs({
  color: '#777777',
  variant: 'caption',
})`
  margin-left: 16px;
`;
export const PlaceHolderText = styled(Text).attrs({
  color: '#777777',
  variant: 'caption',
  mt: 8,
})``;
