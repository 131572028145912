import { defineMessages } from 'react-intl';

export const editExpertProfileInfoForm = defineMessages({
  firstName: {
    id: 'edit-expert-info-modal-error_first_name',
    defaultMessage: 'First name cannot be blank',
  },
  invalidPhone: {
    id: 'edit-expert-info-modal-error_invalid_phone',
    defaultMessage: 'Please use a valid phone number',
  },
  blankPhone: {
    id: 'edit-expert-info-modal-error_blank_phone',
    defaultMessage: 'Phone number cannot be blank',
  },
  invalidEmail: {
    id: 'edit-expert-info-modal-error_email-1',
    defaultMessage: 'Please use a valid email address.',
  },
  secondaryEmailSameAsPrimaryEmail: {
    id: 'edit-expert-info-modal-error_email-2',
    defaultMessage: 'Your secondary email cannot be the same as your email',
  },
});
