import { createAction } from 'redux-actions';

import { Event } from 'src/global/models';
import { ExpertRating } from 'src/global/models/User';
import { ReduxThunkAction } from 'src/global/store';

export enum ProfileFields {
  // Users
  PHONE_NUMBER = 'phoneNumber',
  LOCATION = 'location',
  PHOTO = 'photo',
  RESUME = 'resume',
  ABOUT_ME = 'aboutMe',
  PORTFOLIO = 'portfolio',
  SOCIAL_MEDIA = 'socialMedia',
  WHATSAPP_NUMBER = 'whatsappNumber',
  CAREER_START_DATE = 'careerStartDate',

  // UserSkills
  SKILLS = 'skills',

  // Experiences
  WORK_EXPERIENCE = 'workExperience',
  EDUCATION = 'education',
  AWARD = 'award',
  CCA = 'cca',

  // Preferences
  JOB_FUNCTIONS = 'jobFunctions',
  JOB_TYPES = 'jobTypes',
  WORK_LOCATIONS = 'workLocations',
  SALARY_EXPECTATION = 'salaryExpectation',

  // Certificates
  CERTIFICATE = 'certificate',
}

type ExpertClassProfilePageProperties = {
  classId: Event['shortId'];
  classCategory: string[];
  classType: string;
  classStatus: string;
  expertScore: ExpertRating['finalRating'] | null;
};

const Actions = {
  EDIT_PROFILE: 'glints/GoogleTagManager/PROFILE_EDIT_FIELD',
  EXPERT_CLASS_PROFILE_PAGE:
    'glints/GoogleTagManager/CLICK_EXPERT_CLASS_PROFILE_PAGE',
  UPDATE_PREFERENCES: 'glints/GoogleTagManager/SET_PREFERENCES',
};

export const trackEditProfileField = createAction(
  Actions.EDIT_PROFILE,
  (field: ProfileFields) => ({
    field,
    actionType: 'update',
  })
);

export const trackDeleteProfileField = createAction(
  Actions.EDIT_PROFILE,
  (field: ProfileFields) => ({
    field,
    actionType: 'delete',
  })
);

export const trackUpdatePreferences = createAction(Actions.UPDATE_PREFERENCES);

export const trackClickExpertClassProfilePage =
  (eventProperties: ExpertClassProfilePageProperties): ReduxThunkAction<any> =>
  (dispatch) =>
    dispatch({
      type: Actions.EXPERT_CLASS_PROFILE_PAGE,
      payload: {
        ...eventProperties,
        expertScore: eventProperties.expertScore ?? 'null',
      },
    });
