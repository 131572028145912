import styled from 'styled-components';

export type ContainerProps = { hideBackground?: boolean };
export const Container = styled.div<ContainerProps>`
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .aries-profile {
    display: block;
    ${({ hideBackground }) =>
      hideBackground
        ? `div:after {
            background: transparent;
          }`
        : ''}
  }
  input[type='file'] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
`;
